<template>
  <div class="text-center">
    <template v-if="checkPermission('StatusJadwalDokterResource.DELETE.StatusJadwalDokter.id')">
      <button
        @click="deleteAbsentDokter()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Hapus"
      >
        <i class="la la-trash"></i>
      </button>
    </template>
  </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const StatusJadwalDokterRepository = RepositoryFactory.get("statusJadwal");
const checkPermission = new CheckPermission();
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
  props: {
    jadwalVue: { type: Object },
    rowData: { type: Object }
  },
  methods: {
    deleteAbsentDokter() {
      var vx = this;
      let instance = new SweetAlert().showConfirm({
        swalTitle: "Apakah Anda yakin?",
        swalText: "Absen Dokter akan terhapus.",
        swalType: "warning",
        onConfirmButton: function () {
          blockUI.blockPage();
          vx.delete();
        },
        onCancelButton: function () {
          blockUI.unblockPage();
        }
      });
    },
    async delete() {
      var vx = this;

      await StatusJadwalDokterRepository.deleteStatusSchedule(this.rowData.id)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.jadwalVue.fetch(resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Absen Dokter berhasil terhapus.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockPage();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Absen Dokter gagal terhapus.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockPage();
            }
          });
        });
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    },
    showToastError: function (message) {
      toastr.error(message);
    },
  }
}
</script>

<style>
</style>
