<template>
  <div>
    <base-header base-title="Ketidakhadiran Dokter"></base-header>
    <!-- begin:: Content -->
    <div
      class="kt-content kt-grid__item kt-grid__item--fluid"
      id="kt_content"
    >
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="headTitle"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
              <auto-refresh
                :table-name="tableName"
                :on-refresh="fetch"
              ></auto-refresh>&nbsp;&nbsp;
              <router-link
                class="btn btn-default btn-bold btn-upper btn-font-sm"
                :to="{ name: 'dokter' }"
              >
                <i class="flaticon2-back"></i>
                Kembali
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="kt-portlet__body"
          v-if="isLoaded"
        >
          <data-tables :table="table"></data-tables>
        </div>
        <div
          v-else
          class="m-auto p-5 d-flex flex-row h-100"
        >
          <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
          <h5 class="my-auto pl-5">Loading ...</h5>
        </div>
      </div>
    </div>
    <!-- end:: Content -->
  </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import AutoRefresh from "./../_general/AutoRefresh";
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import DataTables from "./../_table/DataTables";
import BtnAction from "./_components/BtnActionAbsent";
import LoadSpinner from "./../_general/LoadSpinner";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const StatusJadwalDokterRepository = RepositoryFactory.get("statusJadwal");

export default {
  components: {
    AutoRefresh,
    DataTables,
    BaseTitle,
    BaseHeader
  },
  data() {
    var vx = this;
    return {
      isLoaded: false,
      dataEdit: null,
      headTitle: "Daftar Ketidakhadiran",
      tableName: "table_absent_doctor",
      table: {
        tableName: "table_absent_doctor",
        tableDataResult: [],
        tableColumnDefs: [
          {
            targets: 3,
            width: "15%",
            render: function (t, e, a, n) {
              var momentDate = moment(t);
              return momentDate.format("DD/MM/YYYY");
            }
          },
          {
            targets: 4,
            render: function (t, e, a, n) {
              var hari;

              if (t) {
                if (a.jadwal.hari == "SUNDAY") {
                  hari = "Minggu";
                } else if (a.jadwal.hari == "MONDAY") {
                  hari = "Senin";
                } else if (a.jadwal.hari == "TUESDAY") {
                  hari = "Selasa";
                } else if (a.jadwal.hari == "WEDNESDAY") {
                  hari = "Rabu";
                } else if (a.jadwal.hari == "THURSDAY") {
                  hari = "Kamis";
                } else if (a.jadwal.hari == "FRIDAY") {
                  hari = "Jumat";
                } else if (a.jadwal.hari == "SATURDAY") {
                  hari = "Sabtu";
                } else {
                  hari = "";
                }

                return hari;
              }
            }
          },
          {
            defaultContent: "-",
            targets: "_all"
          },
          {
            targets: -1,
            responsivePriority: 1
          }
        ],
        tableColumns: [
          {
            title: "ID",
            data: "id"
          },
          {
            title: "Nama Dokter",
            data: function(row, type) {
                            if(row.jadwal) {
                                if(row.jadwal.dokter){
                                    return row.jadwal.dokter.nama
                                }
                            }
                            return ''
                            }
          },
          {
            title: "Poli",
            data: function(row, type) {
                            if(row.jadwal) {
                                if(row.jadwal.dokter){
                                  if(row.jadwal.dokter.tujuanRujukan) {
                                    return row.jadwal.dokter.tujuanRujukan.nama
                                  }  
                                }
                            }
                            return ''
                            }
          },
          {
            title: "Tanggal",
            data: "tanggal"
          },
          {
            title: "Hari",
            data: "jadwal.hari"
          },
          {
            title: "Jam Mulai",
            data: function(row, type) {
                            if(row.jadwal) {
                                if(row.jadwal.jamMulai){
                                    return row.jadwal.jamMulai
                                }
                            }
                            return ''
                            }
          },
          {
            title: "Jam Selesai",
             data: function(row, type) {
                            if(row.jadwal) {
                                if(row.jadwal.jamSelesai){
                                    return row.jadwal.jamSelesai
                                }
                            }
                            return ''
                            }
          },
          {
            title: "Actions",
            data: "id",
            createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
              let btnAction = Vue.extend(BtnAction);
              let instance = new btnAction({
                propsData: {
                  jadwalVue: vx,
                  rowData: rowData
                }
              });
              instance.$mount();
              $(cell)
                .empty()
                .append(instance.$el);
            }
          }
        ],
        tableFilter: [
          {
            title: "Nama Dokter",
            type: "combobox"
          },
          {
            title: "Poli",
            type: "combobox"
          },
          {
            title: "Tanggal",
            type: "datepicker"
          },
          {
            title: "Jadwal",
            type: "text"
          },
          {
            title: "Actions",
            type: "actions"
          }
        ]
      }
    };
  },
  methods: {
    async fetch(resolve) {
      new Promise((resolve, reject) => {
        StatusJadwalDokterRepository.get({}, resolve, reject);
      }).then(response => {
        const data = response.data;
        if (this.isLoaded) {
          var datatable = $("#" + this.tableName).DataTable();
          datatable.clear();
          datatable.rows.add(data);
          datatable.draw("full-hold");
          if (resolve !== undefined) {
            resolve(data);
          }
        } else {
          this.table.tableDataResult = data;
          this.isLoaded = true;
        }
      }).catch(error => {
        var msg = (error.message) ? error.message : error;
        this.showToastError('Absent Doctor : ' + msg);
      })
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    },
    showToastError: function (message) {
      toastr.error(message);
    }
  },
  created() {
    this.fetch();
  }
};
</script>
